import React, { useEffect, useState } from "react"
import { InputText } from "primereact/inputtext"
import { Dropdown } from "primereact/dropdown"
import { TYPE_PAGE } from "src/constants/common"
import { RestaurantType, ShopInformationFormType, AddressType, CurrencyType } from "../types"
import { MultiSelect } from "primereact/multiselect"
import { AddressDirectoryControllerApi } from "@soctrip/shipment-service"
import { config } from "src/config/interceptors"
import { SERVICE } from "src/constants/common"
import { InputNumber } from "primereact/inputnumber"
import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { useTranslation } from "react-i18next"
import { InputSwitch } from "primereact/inputswitch"
import "./index.scss"
import { getDepositSystemByRegion } from "src/services/restaurant-service"
import { getCurrencies } from "src/services/billing-service"
import { Nullable } from "primereact/ts-helpers"
import { Calendar, CalendarChangeEvent } from "primereact/calendar"

const ShopInformationForm = (props: ShopInformationFormType) => {
  const { dataForm, typePage, restaurantType } = props
  const [countryData, setCountryData] = useState<AddressType[]>([])
  const [cityData, setCityData] = useState<AddressType[]>([])
  const [districtData, setDistrictData] = useState<AddressType[]>([])
  const [wardData, setWardData] = useState<AddressType[]>([])
  const [showMessage, setShowMessage] = useState<boolean>(false)
  const isForeign = (dataForm.values?.shop_country?.code_name ?? "") != "vn"
  const [depositSystem, setDepositSystem] = useState<number>(0)
  const [currencyData, setCurrencyData] = useState<CurrencyType[]>([])
  const { t } = useTranslation()
  const isInValidField = (name: string) => {
    return !!(dataForm?.touched[`${name}`] && dataForm?.errors[`${name}`])
  }
  const [closingTime, setClosingTime] = useState<Nullable<Date>>(null)
  const [openingTime, setOpeningTime] = useState<Nullable<Date>>(null)

  const itemTemplate = (option: RestaurantType) => {
    return (
      <div className="align-items-center flex">
        <div className="text-14 leading-20 text-gray-700">{option.name}</div>
      </div>
    )
  }
  const headerTemplate = () => <div className="hidden" />

  const handleOpeningTimeChange = (e: CalendarChangeEvent) => {
    const newOpeningTime = e.value as Date | null
    setOpeningTime(newOpeningTime)
    dataForm.setFieldValue("opening_time", newOpeningTime)
  }

  const handleClosingTimeChange = (e: CalendarChangeEvent) => {
    const newClosingTime = e.value as Date | null
    setClosingTime(newClosingTime)
    dataForm.setFieldValue("closing_time", newClosingTime)
  }

  const getCountryList = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    new AddressDirectoryControllerApi(config(SERVICE.SHIPMENT))
      .addressCountriesGet()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        if (res?.data?.data?.length) {
          const result: AddressType[] = []
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          res.data.data.forEach((i: any) => {
            result.push({
              id: i?.country_id,
              name: i?.country_name,
              code_name: i?.code_name,
              postal_code: i?.postal_code || "",
            })
          })
          setCountryData(result)
        }
      })
      .catch((err) => console.log(err))
  }

  const getCityList = (countryId: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    new AddressDirectoryControllerApi(config(SERVICE.SHIPMENT))
      .addressProvincesGet(countryId)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        if (res?.data?.data?.length) {
          const result: AddressType[] = []
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          res.data.data.forEach((i: any) => {
            result.push({
              id: i?.province_id,
              name: i?.province_name,
              code_name: i?.code_name,
              postal_code: i?.postal_code || "",
            })
          })
          setCityData(result)
        }
      })
      .catch((err) => console.log(err))
  }
  const getDistrictList = (cityId: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    new AddressDirectoryControllerApi(config(SERVICE.SHIPMENT))
      .addressDistrictsGet(cityId)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        if (res?.data?.data?.length) {
          const result: AddressType[] = []
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          res.data.data.forEach((i: any) => {
            result.push({
              id: i?.district_id,
              name: i?.district_name,
              code_name: i?.code_name,
              postal_code: i?.postal_code || "",
            })
          })
          setDistrictData(result)
        }
      })
      .catch((err) => console.log(err))
  }

  const getWardList = (districtId: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    new AddressDirectoryControllerApi(config(SERVICE.SHIPMENT))
      .addressWardsGet(districtId)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        if (res?.data?.data?.length) {
          const result: AddressType[] = []
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          res.data.data.forEach((i: any) => {
            result.push({
              id: i?.ward_id,
              name: i?.ward_name,
              code_name: i?.code_name,
              postal_code: i?.postal_code || "",
            })
          })
          setWardData(result)
        }
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((err: any) => console.log(err))
  }
  //trigger country change
  useEffect(() => {
    if (dataForm?.values?.shop_country) {
      setCityData([])
      setDistrictData([])
      // setWardData([])
      dataForm.setFieldValue("shop_city", null)
      dataForm.setFieldValue("shop_district", null)
      dataForm.setFieldValue("shop_ward", null)

      getCityList(dataForm?.values?.shop_country?.id)
    }
  }, [dataForm?.values?.shop_country])

  // trigger city change
  useEffect(() => {
    if (dataForm?.values?.shop_city) {
      setDistrictData([])
      // setWardData([])
      dataForm.setFieldValue("shop_district", null)
      dataForm.setFieldValue("shop_ward", null)

      getDistrictList(dataForm?.values?.shop_city?.id)
    }
  }, [dataForm?.values?.shop_city])

  // trigger district change
  useEffect(() => {
    if (dataForm?.values?.shop_district) {
      setWardData([])
      dataForm.setFieldValue("shop_ward", null)

      getWardList(dataForm?.values?.shop_district?.id)
    }
  }, [dataForm?.values?.shop_district])

  useEffect(() => {
    function initView() {
      getCountryList()
    }
    initView()
  }, [])
  useEffect(() => {
    if (dataForm.values?.shop_country?.id) {
      setDepositSystem(0)
      dataForm.setFieldValue("deposit", null)
      getDepositSystemByRegion(dataForm.values.shop_country.id)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((res: any) => {
          if (res?.data?.data) {
            setDepositSystem(res.data.data as number)
          }
        })
        .catch((err) => console.log(err))
    }
  }, [dataForm.values?.shop_country])

  useEffect(() => {
    async function fetchCurrencies() {
      try {
        const res = await getCurrencies()
        setCurrencyData(res?.data?.data ?? [])
      } catch (error) {
        console.log(error)
      }
    }
    fetchCurrencies()
  }, [])

  const selectedCurrencyTemplate = (option: CurrencyType) => {
    if (option) {
      return (
        <div className="align-items-center flex">
          <div>
            {option.code} - {option.name}
          </div>
        </div>
      )
    }
    return <span>{t("request-open-page.select-a-currency") ?? ""}</span>
  }

  const countryOptionTemplate = (option: CurrencyType) => {
    return (
      <div className="align-items-center flex">
        <div>
          {option.code} - {option.name}
        </div>
      </div>
    )
  }

  return (
    <div className="rounded mt-3 w-full rounded-3 border-gray-200 bg-white p-3">
      <div className="flex flex-col gap-1 md:flex-col xl:flex-row">
        <div className="md:flex-1">
          <div className="flex flex-col">
            <span className="leading-28px text-[18px] font-semibold text-gray-900">
              {capitalizedFirst(t("request-open-page.restaurant-info"))}
            </span>
          </div>
        </div>
        <div className="flex w-full flex-col xl:w-[760px]">
          <div className="flex flex-col">
            <span className="text-14 font-medium leading-20 text-gray-700">
              {capitalizedFirst(t("request-open-page.merchant-label"))}
              <span className="ml-[4px] text-red-600">{"*"}</span>
            </span>
            <InputText
              className={`mt-[6px] w-full ${isInValidField("shop_name") ? "p-invalid" : ""} `}
              placeholder={capitalizedFirst(t("request-open-page.merchant-placeholder"))}
              name="shop_name"
              value={dataForm?.values?.shop_name}
              onChange={(e) => {
                if (!dataForm?.touched?.shop_name)
                  dataForm.setTouched({
                    ...dataForm.touched,
                    ...{ shop_name: true },
                  })
                dataForm.setFieldValue("shop_name", e.target.value)
              }}
            />
          </div>
          {dataForm.errors.shop_name && dataForm.touched.shop_name && (
            <span className="mt-2px text-[12px] font-semibold text-[#e24c4c]">
              {dataForm.errors.shop_name}
            </span>
          )}
        
          <fieldset className="rounded-3 border border-gray-200 p-3 pt-0 mt-[16px]">
            <legend className="text-sm px-2 font-medium text-gray-900">{capitalizedFirst(t("request-open-page.address-information"))}</legend>
            <div id="shopInformation" className="mt-[16px] grid grid-cols-1 gap-3 md:grid-cols-2">
              {/* COUNTRY */}
              <div className="flex flex-1 flex-col">
                <span className="text-14 font-medium leading-20 text-gray-700">
                  {capitalizedFirst(t("request-open-page.country"))}
                  <span className="text-red-600">{" *"}</span>
                </span>
                <Dropdown
                  value={dataForm?.values?.shop_country}
                  name="shop_country"
                  onChange={(e) => {
                    if (!dataForm?.touched?.shop_country)
                      dataForm.setTouched({
                        ...dataForm.touched,
                        ...{ shop_country: true },
                      })
                    dataForm.setFieldValue("shop_country", e.value)
                    dataForm.setFieldValue("shop_city", null)
                    dataForm.setFieldValue("shop_district", null)
                    if(dataForm?.values?.shop_country?.code_name === "us") {
                      dataForm.setFieldValue("sub_shop_address", "")
                    }
                  }}
                  options={countryData}
                  optionLabel="name"
                  placeholder={capitalizedFirst(t("request-open-page.country-placeholder"))}
                  className={`mt-[6px] w-full ${isInValidField("shop_country") ? "p-invalid" : ""} `}
                />
                {dataForm.errors.shop_country && dataForm.touched.shop_country && (
                  <span className="mt-2px text-[12px] font-semibold text-[#e24c4c]">
                    {dataForm.errors.shop_country}
                  </span>
                )}
              </div>
              {/* CITY */}
              <div className="flex flex-1 flex-col">
                <span className="text-14 font-medium leading-20 text-gray-700">
                  {isForeign ? capitalizedFirst(t("request-open-page.state-province")) : capitalizedFirst(t("request-open-page.city-province"))}
                  <span className="text-red-600">{" *"}</span>
                </span>
                <Dropdown
                  value={dataForm?.values?.shop_city}
                  name="shop_city"
                  onChange={(e) => {
                    if (!dataForm?.touched?.shop_city)
                      dataForm.setTouched({
                        ...dataForm.touched,
                        ...{ shop_city: true },
                      })
                    dataForm.setFieldValue("shop_city", e.value)
                  }}
                  options={cityData}
                  optionLabel="name"
                  placeholder={capitalizedFirst(t("request-open-page.select-a")) + " " + (isForeign ? capitalizedFirst(t("request-open-page.state-province")) : capitalizedFirst(t("request-open-page.city-province")))}
                  className={`mt-[6px] w-full ${isInValidField("shop_city") ? "p-invalid" : ""} `}
                />
                {dataForm.errors.shop_city && dataForm.touched.shop_city && (
                  <span className="mt-2px text-[12px] font-semibold text-[#e24c4c]">
                    {dataForm.errors.shop_city}
                  </span>
                )}
              </div>
              {/* DISTRICT */}
              <div className="flex flex-1 flex-col">
                <span className="text-14 font-medium leading-20 text-gray-700">
                  {isForeign ? capitalizedFirst(t("request-open-page.city")) : capitalizedFirst(t("request-open-page.district"))}
                  <span className="text-red-600">{" *"}</span>
                </span>
                <Dropdown
                  name="shop_district"
                  value={dataForm?.values?.shop_district}
                  onChange={(e) => {
                    if (!dataForm?.touched?.shop_district)
                      dataForm.setTouched({
                        ...dataForm.touched,
                        ...{ shop_district: true },
                      })
                    dataForm.setFieldValue("shop_district", e.value)
                  }}
                  options={districtData}
                  optionLabel="name"
                  placeholder={capitalizedFirst(t("request-open-page.select-a")) + " " + (isForeign ? capitalizedFirst(t("request-open-page.city")) : capitalizedFirst(t("request-open-page.district")))}
                  className={`mt-[6px] w-full ${isInValidField("shop_district") ? "p-invalid" : ""} `}
                />
                {dataForm.errors.shop_district && dataForm.touched.shop_district && (
                  <span className="mt-2px text-[12px] font-semibold text-[#e24c4c]">
                    {dataForm.errors.shop_district}
                  </span>
                )}
              </div>
              {/* WARD */}
              <div className="flex flex-1 flex-col">
                <span className="text-14 font-medium leading-20 text-gray-700">{capitalizedFirst(t("request-open-page.ward"))}</span>
                <Dropdown
                  value={dataForm?.values?.shop_ward}
                  name="shop_ward"
                  onChange={(e) => {
                    if (!dataForm?.touched?.shop_ward)
                      dataForm.setTouched({
                        ...dataForm.touched,
                        ...{ shop_ward: true },
                      })
                    dataForm.setFieldValue("shop_ward", e.value)
                  }}
                  options={wardData}
                  optionLabel="name"
                  placeholder={capitalizedFirst(t("request-open-page.select-a-ward"))}
                  className={`mt-[6px] w-full ${isInValidField("shop_ward") ? "p-invalid" : ""} `}
                />
                {dataForm.errors.shop_ward && dataForm.touched.shop_ward && (
                  <span className="mt-2px text-[12px] font-semibold text-[#e24c4c]">
                    {dataForm.errors.shop_ward}
                  </span>
                )}
              </div>
            </div>

            {/* ADDRESS LINE 1*/}
            <div className="mt-[16px] flex flex-col">
              <span className="text-14 font-medium leading-20 text-gray-700">
                {
                  capitalizedFirst(t(dataForm?.values?.shop_country?.code_name === "us" ? "request-open-page.address-line-first" : "request-open-page.address-line"))
                }
                <span className="text-red-600"> *</span>
              </span>
              <InputText
                className={`mt-[6px] w-full ${isInValidField("shop_address") ? "p-invalid" : ""} `}
                placeholder={capitalizedFirst(t(dataForm?.values?.shop_country?.code_name === "us" ? "request-open-page.address-line-first-placeholder" : "request-open-page.enter-address-line"))}
                value={dataForm?.values?.shop_address}
                name="shop_address"
                onChange={(e) => {
                  if (!dataForm?.touched?.shop_address)
                    dataForm.setTouched({
                      ...dataForm.touched,
                      ...{ shop_address: true },
                    })
                  dataForm.setFieldValue("shop_address", e.target.value)
                }}
              />
              <span className="mt-[6px] text-[12px] font-normal leading-[16px]">
                {capitalizedFirst(t("request-open-page.note-address-1"))}
              </span>
              {dataForm.errors.shop_address && dataForm.touched.shop_address && (
                <span className="mt-2px text-[12px] font-semibold text-[#e24c4c]">
                  {dataForm.errors.shop_address}
                </span>
              )}
            </div>

            {/* ADDRESS LINE 2 */}
            {
              dataForm?.values?.shop_country?.code_name === "us" ? (
                <>
                  <div className="mt-[16px] flex flex-col">
                    <span className="text-14 font-medium leading-20 text-gray-700">
                      {capitalizedFirst(t("request-open-page.address-line-second"))}
                    </span>
                    <InputText
                      className={`mt-[6px] w-full ${
                        isInValidField("sub_shop_address") ? "p-invalid" : ""
                      } `}
                      placeholder={capitalizedFirst(t("request-open-page.address-line-second-placeholder"))}
                      value={dataForm?.values?.sub_shop_address}
                      name="sub_shop_address"
                      onChange={(e) => {
                        if (!dataForm?.touched?.sub_shop_address)
                          dataForm.setTouched({
                            ...dataForm.touched,
                            ...{ sub_shop_address: true },
                          })
                        dataForm.setFieldValue("sub_shop_address", e.target.value)
                      }}
                    />
                    <span className="mt-[6px] text-[12px] font-normal leading-[16px]">
                      {capitalizedFirst(t("request-open-page.note-address-2"))}
                    </span>
                    {dataForm.errors.sub_shop_address && dataForm.touched.sub_shop_address && (
                      <span className="mt-2px text-[12px] font-semibold text-[#e24c4c]">
                        {dataForm.errors.sub_shop_address}
                      </span>
                    )}
                  </div>
                </>
              ) : null
            }
          </fieldset>

          {/* CURRENCY */}
          <div className="mt-3 flex flex-col">
            <span className="text-14 font-medium leading-20 text-gray-700">
              {t("request-open-page.currency")}
              <span className="text-red-600">{" *"}</span>
            </span>
            <Dropdown
              value={dataForm?.values?.currency}
              name="currency"
              onChange={(e) => {
                if (!dataForm?.touched?.currency)
                  dataForm.setTouched({
                    ...dataForm.touched,
                    ...{ currency: true },
                  })
                dataForm.setFieldValue("currency", e.value)
              }}
              options={currencyData}
              optionLabel="name"
              optionValue="code"
              valueTemplate={selectedCurrencyTemplate}
              itemTemplate={countryOptionTemplate}
              placeholder={t("request-open-page.select-a-currency") ?? ""}
              className={`mt-[6px] w-full ${isInValidField("currency") ? "p-invalid" : ""} `}
            />
            {dataForm.errors.currency && dataForm.touched.currency && (
              <span className="mt-2px text-[12px] font-semibold text-[#e24c4c]">
                {dataForm.errors.currency}
              </span>
            )}
          </div>

          {typePage === TYPE_PAGE.FOOD ? (
            <div className="mt-3 flex flex-col">
              <span className="text-14 font-medium leading-20 text-gray-700">
                {capitalizedFirst(t("request-open-page.category-type"))}
                <span className="text-red-600 ml-4px">{"*"}</span>
              </span>
              <MultiSelect
                name="shop_category"
                value={dataForm?.values?.shop_category}
                options={restaurantType}
                onChange={(e) => {
                  if (!dataForm?.touched?.shop_category)
                    dataForm.setTouched({
                      ...dataForm.touched,
                      ...{ shop_category: true },
                    })
                  dataForm.setFieldValue("shop_category", e.value)
                }}
                optionLabel="name"
                placeholder={capitalizedFirst(t("request-open-page.select-category"))}
                panelHeaderTemplate={headerTemplate}
                itemTemplate={itemTemplate}
                className={`mt-[6px] w-full ${isInValidField("shop_category") ? "p-invalid" : ""}`}
                display="chip"
              />
              {dataForm.errors.shop_category && dataForm.touched.shop_category && (
                <span className="mt-2px text-[12px] font-semibold text-[#e24c4c]">
                  {dataForm.errors.shop_category}
                </span>
              )}
            </div>
          ) : null}

          {/* DEPOSIT */}
          {
            !depositSystem || !dataForm.values?.shop_country ? null : (
              <>
                <div className="mt-[16px] flex flex-col gap-1">
                  <div id="customSwitch" className="flex items-center gap-1">
                    <InputSwitch
                      checked={dataForm?.values?.is_deposit}
                      onChange={(e) => {
                        if (!dataForm?.touched?.deposit)
                          dataForm.setTouched({
                            ...dataForm.touched,
                            ...{ deposit: true },
                          })
                        setShowMessage(!showMessage)
                        dataForm.setFieldValue("is_deposit", e.value)
                      }}
                    />
                    <span className="flex-1 text-[14px] font-medium leading-[20px] text-gray-700">
                      {capitalizedFirst(t("request-open-page.deposit-detail"))}
                    </span>
                  </div>
                  <div className="mt-3 flex flex-col">
                    <span className="text-14 font-medium leading-20 text-gray-700">
                      {capitalizedFirst(t("request-open-page.deposit-amount"))}
                      <span className="text-red-600 ml-4px">{"*"}</span>
                    </span>
                    <InputNumber
                      min={0}
                      mode="currency"
                      currency="USD"
                      max={depositSystem}
                      disabled={!depositSystem}
                      className={`mt-[6px] w-full ${isInValidField("deposit") ? "p-invalid" : ""} ${
                        !depositSystem ? "opacity-50" : ""
                      } `}
                      placeholder= {capitalizedFirst(t("request-open-page.enter-deposit-amount"))}
                      value={!depositSystem ? 0 : dataForm?.values?.deposit}
                      name="deposit"
                      onChange={(e) => {
                        if (!dataForm?.touched?.deposit)
                          dataForm.setTouched({
                            ...dataForm.touched,
                            ...{ deposit: true },
                          })
                        dataForm.setFieldValue("deposit", e.value)
                      }}
                      onValueChange={(e) => {
                        if (!dataForm?.touched?.deposit)
                          dataForm.setTouched({
                            ...dataForm.touched,
                            ...{ deposit: true },
                          })
                        dataForm.setFieldValue("deposit", e.value)
                      }}
                    />
                  </div>
                  {dataForm.errors.deposit && dataForm.touched.deposit && (
                    <span className="mt-2px text-[12px] font-semibold text-[#e24c4c]">
                      {dataForm.errors.deposit}
                    </span>
                  )}
                  {showMessage && (
                    <div className="flex gap-[12px] rounded-3 bg-blue-50 px-[16px] py-[12px] text-blue-600">
                      <i className="sctr-icon-info-circle text-[20px]" />
                      <span className="text-[14px] leading-[20px]">
                        {capitalizedFirst(t("request-open-page.deposit-info"))}
                      </span>
                    </div>
                  )}
                </div>
              </>
            )
          }

          {/* SUBMIT BILL */}
          <div className="mt-[16px] flex flex-col gap-1">
            <div id="customSwitch" className="flex items-center gap-1">
              <InputSwitch
                checked={dataForm?.values?.is_campaign}
                onChange={(e) => dataForm.setFieldValue("is_campaign", e.value)}
              />
              <span className="flex-1 text-[14px] font-medium leading-[20px] text-gray-700">
                {capitalizedFirst(t("request-open-page.submit-bill"))}
              </span>
            </div>
          </div>

          {/* OPEN TIME --- CLOSE TIME  */}
          <div id="activityTime" className="mt-[16px] grid grid-cols-1 gap-3 md:grid-cols-2">
            {/* OPEN TIME */}
            <div className="flex flex-1 flex-col">
              <span className="text-14 font-medium leading-20 text-gray-700">
                {capitalizedFirst(t("request-open-page.opening-time"))}
                <span className="text-red-600">{" *"}</span>
              </span>
              <Calendar
                name="opening_time"
                value={openingTime}
                onChange={handleOpeningTimeChange}
                timeOnly
                showIcon
                placeholder={capitalizedFirst(t("request-open-page.activity-time"))}
                className={`mt-[6px] w-full ${isInValidField("opening_time") ? "p-invalid" : ""} `}
              />
              {dataForm.errors.opening_time && dataForm.touched.opening_time && (
                <span className="mt-2px text-[12px] font-semibold text-[#e24c4c]">
                  {dataForm.errors.opening_time}
                </span>
              )}
            </div>

            {/* CLOSE TIME */}
            <div className="flex flex-1 flex-col">
              <span className="text-14 font-medium leading-20 text-gray-700">
                {capitalizedFirst(t("request-open-page.closing-time"))}
                <span className="text-red-600">{" *"}</span>
              </span>
              <Calendar
                name="closing_time"
                value={closingTime}
                onChange={handleClosingTimeChange}
                timeOnly
                showIcon
                placeholder={capitalizedFirst(t("request-open-page.activity-time"))}
                className={`mt-[6px] w-full ${isInValidField("closing_time") ? "p-invalid" : ""} `}
              />
              {dataForm.errors.closing_time && dataForm.touched.closing_time && (
                <span className="mt-2px text-[12px] font-semibold text-[#e24c4c]">
                  {dataForm.errors.closing_time}
                </span>
              )}
            </div>
            
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShopInformationForm
